import React from 'react';
import { Route, Switch } from 'react-router-dom';

import './App.css';
import 'antd/dist/antd.css';

import Header from './Header/Header';
import MainContainer from '../containers/MainContainer';
import NoMatch from '../components/NoMatch/NoMatch';

import Particles from 'react-particles-js';
import ParticlesJson from '../assets/particles.json';

function App() {
  return (
      <div className="app">
          <Header />

          <Particles className="particles-js" params={ParticlesJson} height="100vh" width="100vw"/>

          <Switch>
            <Route exact path="/" component={MainContainer}/>
            <Route path='/*' exact={true} component={NoMatch} />
          </Switch>
      </div>
  );
}

export default App;
