import React from 'react';
import { Row, Col } from 'antd';

import './Cover.css';

const Cover = () => {
	return(
			<Row type="flex" className="cover-section">
				<Col xs ={{span: 0}} span={4}></Col>
				<Col xs ={{span: 24}} lg={{ span: 24}}>
					<div className="cover">
						<h1>Adam Espinoza</h1>
						<p>Full-Stack Development  •  Product Management  •  UI/UX</p>
					</div>
				</Col>
				<Col xs ={{span: 0}} span={4}></Col>
			</Row>
	);
}

export default Cover
