import React from 'react';
import { Row, Col, Card } from 'antd';

import './Projects.css';
import ConsensusCookbook from '../../assets/img/consensuscookbook.png';
import HitchHikers from '../../assets/img/hitchhikers.png';
import OldSpice from '../../assets/img/oldspice.png';
import OutOfPocket from '../../assets/img/code2040.png';
import Sanity from '../../assets/img/sanity.png';
import UpBeat from '../../assets/img/upbeat.png';

const { Meta } = Card;

class Projects extends React.Component {


  render() {
    return(
      <Row type="flex" className="section-row">
        <Col span={24}>
          <div className="header">
            <h1 className="tag">How I spend my free time</h1>
            <h1 className="mainheadline">Side Projects</h1>
          </div>
            <Row type="flex" justify="space-around" style={{padding: "0 3.5em"}}>
              <Col>
                <a href="https://github.com/Adespinoza/ConsensusCookbook" target="_blank" rel="noopener noreferrer">
                  <Card
                    hoverable
                    style={{ width: 350, marginBottom: "3.5em" }}
                    cover={<img alt="ConsensusCookbook" src={ConsensusCookbook} style={{height: 350 }} />}
                  >
                  <Meta title="ConsensusCookbook" description="A blockchain application for your cookbook recipes 🍽🍳" />
                  </Card>
                </a>
              </Col>
              <Col>
                <a href="https://github.com/aneelyelamanchili/Hitchhikers" target="_blank" rel="noopener noreferrer">
                  <Card
                    hoverable
                    style={{ width: 350, marginBottom: "3.5em" }}
                    cover={<img alt="HitchHikers" src={HitchHikers}
                    style={{height: 350 }} />}
                  >
                  <Meta title="HitchHikers" description="On-demand application to allow ride sharing between users across longer distances 🚗🚙" />
                  </Card>
                </a>
              </Col>
              <Col>
                <a href="https://github.com/aneelyelamanchili/Sanity" target="_blank" rel="noopener noreferrer">
                  <Card
                    hoverable
                    style={{ width: 350, marginBottom: "3.5em" }}
                    cover={<img alt="Sanity" src={Sanity}
                    style={{height: 350 }} />}
                  >
                  <Meta title="$anity" description="iOS app that consolidates financial information into one location 💰💹" />
                  </Card>
                </a>
              </Col>
            </Row>

            <Row type="flex" justify="space-around" style={{padding: "0 3.5em"}}>
              <Col>
                <a href="https://github.com/Adespinoza/outofpocket" target="_blank" rel="noopener noreferrer">
                  <Card
                    hoverable
                    style={{ width: 350, marginBottom: "3.5em" }}
                    cover={<img alt="OutOfPocket" src={OutOfPocket} />}
                  >
                  <Meta title="Out of Pocket" description="An application that promotes inclusive language through machine learning 🗣✊" />
                  </Card>
                </a>
              </Col>
              <Col>
                <a href="https://github.com/Adespinoza/OldSpiceVoicemailApp" target="_blank" rel="noopener noreferrer">
                  <Card
                    hoverable
                    style={{ width: 350, marginBottom: "3.5em" }}
                    cover={<img alt="OldSpice" src={OldSpice} />}
                  >
                  <Meta title="Old Spice Voicemail Generator" description="Creates an mp3 file voicemail message in Isaiah Amir Mustafa's voice ⛵🌴" />
                  </Card>
                </a>
              </Col>
              <Col>
                <a href="https://github.com/Adespinoza/UpBeat_TrojanHacks2015" target="_blank" rel="noopener noreferrer">
                  <Card
                    hoverable
                    style={{ width: 350 }}
                    cover={<img alt="UpBeat" src={UpBeat} />}
                  >
                  <Meta title="UpBeat" description="Application that allows users to vote on song suggestions from a precompiled list 📱🎶" />
                  </Card>
                </a>
              </Col>
            </Row>
        </Col>
      </Row>
    );
  }
}

export default Projects
