import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'antd';

import error from '../../assets/404.svg';
import './NoMatch.css'

function NoMatch () {
  return(
    <Row type="flex">
      <Col span={4}></Col>
      <Col span={16}>
        <div className="cover">
          <img src={error} className="error" alt="logo" />
        <Link to={`/`}>
          <Button className="errorbutton">Go back home</Button>
          </Link>
        </div>
      </Col>
      <Col span={4}></Col>
    </Row>
  );
}


export default NoMatch;
