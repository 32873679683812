import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';

import logo from '../../logo.svg';
import './Header.css';

const Header = (props) => {
	return(
		<div className = "navbar">
			<Row>
				<Col span={1}></Col>
				<Col span={22}>
					<Link to={`/`}>
						<img src={logo} className="logo" alt="logo" />
					</Link>
				</Col>
				<Col span={1}></Col>
			</Row>
		</div>
	)
}

export default React.memo(Header)
