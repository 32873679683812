import React from 'react';

import FooterMedia from './FooterMedia';
import './Footer.css';

class Footer extends React.Component {

  render() {
    return(
      <React.Fragment>
        <div className="footer" id="contact">
          <div className="section">
            <div className="tagline">Where does our journey go?</div>
              <h2 className="heading-2 white footerheading">Let's find out!</h2>
              <p className="paragraph-closing">I'm always looking for new opportunities and endeavors! Please don't hesitate to reach out or contact me at <a target="_blank" href="mailto:adamespi@usc.edu" rel="noopener noreferrer" className="goldenparagraphlinks">adamespi@usc.edu</a> for potential employment, future project ideas, or to just say hello. I will be sure to get back to you as soon as I can!</p>
              <a href="mailto:adamespi@usc.edu" className="button gold goldondark w-inline-block">
                <div>Write an email</div>
              </a>
            </div>

            <FooterMedia />
          </div>
        </React.Fragment>
    );
  }
}

export default Footer
