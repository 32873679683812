import React from 'react';
import { Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faGithub, faMedium, faLinkedin, faAngellist } from '@fortawesome/free-brands-svg-icons';

import './Footer.css';

class FooterMedia extends React.Component {

  render() {
    return(
      <div className="socialmedia">
        <div className="storefooterlinkswrapper">
          <Row type="flex">
            <Col span={12}>
              <div className="faicon">
                <a href="https://github.com/Adespinoza" target="_blank" rel="noopener noreferrer" className="fa fa-instagram">
                  <FontAwesomeIcon icon={faGithub}/>
                </a>
              </div>

              <div className="faicon">
                <a target="_blank" href="https://www.linkedin.com/in/adamespinoza/" className="fa fa-linkedin" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faLinkedin}/>
                </a>
              </div>

              <div className="faicon">
                <a href="https://angel.co/adamespi" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faAngellist}/>
                </a>
              </div>

              <div className="faicon">
                <a href="https://twitter.com/adamespi_" target="_blank" rel="noopener noreferrer" className="fa fa-instagram">
                  <FontAwesomeIcon icon={faTwitter}/>
                </a>
              </div>

              <div className="faicon">
                <a target="_blank" href="https://medium.com/@adamespinoza" className="fa fa-medium" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faMedium}/>
                </a>
              </div>
            </Col>
            <Col span={12}>
              <div className="tagline">
                <h3>Made with <a target="_blank" href="https://github.com/Adespinoza/" rel="noopener noreferrer" className="goldenparagraphlinks">love</a>.</h3>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default FooterMedia
