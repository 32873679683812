import React from 'react';
import { Divider } from 'antd';

import Cover from '../components/Cover/Cover';
import About from '../components/About/About';
import Projects from '../components/Projects/Projects';
import Footer from '../components/Footer/Footer';

class MainContainer extends React.Component {
	render() {
		return(
			<React.Fragment>
				<Cover />

				<About />

				<Divider />

				<Projects />

				<Footer />
			</React.Fragment>
		);
	}
}

export default MainContainer
