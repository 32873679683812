import React from 'react';
import { Row, Col, Tag } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faTwitter, faMedium, faLinkedin, faAngellist } from '@fortawesome/free-brands-svg-icons';

import Adam from '../../assets/adam.jpg';
import './About.css';

// Arrays used to map skills
const language = ['Python', 'Javascript', 'HTML', 'CSS', 'Ruby', 'SQL', 'Java'];
const frameworks = ['React', 'Ruby on Rails', 'AWS', 'Node.js', 'Angular'];
const database = ['MySQL', 'PostgreSQL'];
const misc = ['Agile Development', 'Product Management', 'UI/UX'];

class About extends React.Component {
  render() {
    return (
     <Row type="flex" className="section-row">
				<Col xs ={{ span: 24 }} lg={{ span: 12 }}>
					<div className="about">
						<h1 className="tag">Hello, my name is,</h1>
						<h1 className="mainheadline">Adam Espinoza</h1>
						<div className="identifiers">
							<h3 className="tag">
								<FontAwesomeIcon icon={faGraduationCap} />
								<span className="graduation">University of Southern California </span>
							</h3>
							<h3 className="tag">
								<FontAwesomeIcon icon={faMapMarkedAlt} />
								<span className="location"> Los Angeles, CA </span>
							</h3>
						</div>
						<div>
							<p className="paragraph">I am a queer Computer Science & Business graduate from USC who enjoys creating well designed projects that impact everyday people.</p>
							<p className="paragraph">My identity is important to me. As a Code2040 Fellow, I believe it's important to help improve Latinx/Black diversity in the tech field. I also love volunteering on projects that help the LGBTQ+ community.</p>
							<p className="paragraph">When I am not coding, you can find me bicycling across Los Angeles, cooking elaborate dishes in the kitchen, and exploring with friends.</p>
						</div>
						<div className ="skills">
							<h2 className="tag"> Skills</h2>
							{language.map((lang, index) => <Tag color="#6384F0" key={index} className="skill">{lang}</Tag>)}
							{frameworks.map((framework, index) => <Tag color="#f5af6f" key={index} className="skill">{framework}</Tag>)}
							{database.map((database, index) => <Tag color="#F78DC5" key={index} className="skill">{database}</Tag>)}
							{misc.map((misc, index) => <Tag color="#39E4A8" key={index} className="skill">{misc}</Tag>)}
						</div>
						<div className="learn">
							<h2 className="tag">Learn More</h2>
								<Col xs ={{ span: 24 }} lg={{ span: 24 }}>
									<div className="faicon-dark">
										<a href="https://github.com/Adespinoza" target="_blank" rel="noopener noreferrer" className="fa fa-instagram">
											<FontAwesomeIcon icon={faGithub}/>
										</a>
									</div>

									<div className="faicon-dark">
										<a target="_blank" href="https://www.linkedin.com/in/adamespinoza/" className="fa fa-linkedin" rel="noopener noreferrer">
											<FontAwesomeIcon icon={faLinkedin}/>
										</a>
									</div>

									<div className="faicon-dark">
										<a href="https://angel.co/adamespi" target="_blank" rel="noopener noreferrer">
											<FontAwesomeIcon icon={faAngellist}/>
										</a>
									</div>

									<div className="faicon-dark">
										<a href="https://twitter.com/adamespi_" target="_blank" rel="noopener noreferrer" className="fa fa-instagram">
											<FontAwesomeIcon icon={faTwitter}/>
										</a>
									</div>

									<div className="faicon-dark">
										<a target="_blank" href="https://medium.com/@adamespinoza" className="fa fa-medium" rel="noopener noreferrer">
											<FontAwesomeIcon icon={faMedium}/>
										</a>
									</div>
								</Col>
						</div>
					</div>
				</Col>
				<Col xs ={{ span: 24 }} lg={{ span: 12 }}>
					<img src={Adam} className="profilepic" alt="profilepic"/>
				</Col>
			</Row>
    );
  }
}

export default About;
